<template>
  <div>
    <a class="come-back" @click.prevent="$router.go(-1)"><div></div>Вернуться назад</a>
    <h2 class="title_categories">
      {{ isEdit ? `Редактирование комментариия №${$route.params.id}` : 'Создание комментария' }}
    </h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="createCommemtAdmin" ref="createCommemtAdmin">
        <div>
          <div class="content-sb">
            <p>Является ли пользователь членом ОП?<span>*</span></p>
            <select name="users" v-model="member" class="input blue" required>
              <option :value="0" selected> Нет </option>
              <option :value="1"> Да </option>
            </select>
          </div>

          <div class="content-sb">
            <p>Имя пользователя / Члена ОП<span>*</span></p>
            <select name="rubric" v-model="user" class="input blue" required>
              <option v-for="(item,index) in listUser" :value="item.id" :key="`user${index}`" :selected="index === 0">
                {{ item.fullName }}
              </option>
            </select>
          </div>

          <div class="content-sb">
            <p>Статус<span>*</span></p>
            <select name="rubric" v-model="dataForm.status" class="input blue" required>
              <option value="CREATED" selected> Создан </option>
              <option value="PUBLISHED"> Опубликован </option>
            </select>
          </div>

          <div class="content-sb">
            <p>Дата<span>*</span></p>
            <input required placeholder="Введите дату" v-model="dataForm.created" type="datetime-local" name="date_time" class="input blue">
          </div>

          <div class="content-sb">
            <p>Заголовок<span>*</span></p>
            <input required v-model="dataForm.title" placeholder="Введите заголовок" type="text" name="name" class="input blue">
          </div>

          <div class="content-sb">
            <p>Текст публикации<span>*</span></p>
            <ckeditor :config="$editorConfig" :editor-url="$root.editorUrl" v-model="dataForm.text" required></ckeditor>
          </div>
        </div>

        <footer class="content-sb btns-create-form">
          <router-link to="/admin/comments_bill" class="btn-white">Отмена</router-link>
          <input type="submit" class="btn-blue" value="Сохранить"/>
        </footer>
      <!-- </div> -->
    </form>

  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
export default {
  name: 'CommentsTopicalCreate',
  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      member: 0,
      user: '',
      dataForm: {
        title: '',
        text: '',
        status: '',
        created: ''
      }
    }
  },
  mounted () {
    if (this.$store.getters.getUsers.length === 0) this.$store.dispatch('setUsers')
    if (this.$store.getters.getAllMembers.length === 0) this.$store.dispatch('setAllMembers')

    // console.log(this.$route.params.id)
    if (this.isEdit) {
      this.$store.dispatch('setDetailCommentTopic', this.$route.params.id)
    }
  },
  computed: {
    getUsers () {
      return this.$store.getters.getUsers
    },
    getAllMembers () {
      return this.$store.getters.getAllMembers
    },
    listUser () {
      return (this.member === 0) ? this.getUsers : this.getAllMembers
    }
  },
  watch: {
    '$store.getters.getDetailCommentTopic' () {
      const dataFromServer = this.$store.getters.getDetailCommentTopic
      this.member = (dataFromServer.isMember === false) ? 0 : 1
      this.listUser.every((item) => {
        if (item.fullName === dataFromServer.user) {
          this.user = item.id
          return false
        } else return true
      })
      var status
      if (dataFromServer.status === 'Создан') {
        status = 'CREATED'
      } else if (dataFromServer.status === 'Опубликован') {
        status = 'PUBLISHED'
      }
      this.dataForm = {
        title: dataFromServer.title,
        text: dataFromServer.text,
        status: status,
        created: (dataFromServer.created.slice(0, -3)).split(' ').join('T')
      }
      this.$forceUpdate()
    }
  },
  methods: {
    createCommemtAdmin () {
      const formData = new FormData()
      for (const key in this.dataForm) {
        var item = this.dataForm[key]
        formData.append(key, item)
      }
      formData.append('is_member', this.member)
      formData.append('author_id', this.user)
      if (!this.isEdit) {
        this.$store.dispatch('createCommemtAdminTopic', [formData, this.$route.params.id])
      } else {
        this.$store.dispatch('updateCommemtTopic', [formData, this.$route.params.id])
      }
    }
  },
  components: {
    TricolorLine
  }
}
</script>

<style scoped lang="scss">
.select, .input{
  width: 33.75rem;
}
.content-sb{
  margin-bottom: 1.25rem;
  span{
    color: red;
  }
}
.wrapper-blog{
  margin-top: 1.25rem;
}
.btns-create-form{
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}
</style>
